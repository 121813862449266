@import '../../common/colors';
.main {
	padding: 60px 30px;
	display: flex;
	justify-content: center;
}
.form {
	box-shadow: 0px 2px 6px rgba(0, 66, 89, 0.06);
	padding: 30px;
	background-color: $bg-account;
	width: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__form1 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 5px;

		& > div {
			width: 350px;
			height: 100%;
			margin-bottom: 30px;
		}
	}

	&__button {
		border: 1px solid $blue-1;
		padding: 15px 50px;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 500;
		box-sizing: border-box;
	}

	&__disabled {
		background-color: white;
		color: $blue-1;
	}
	&__active {
		background-color: $dark-blue;
		color: white;
		cursor: pointer;
		&:hover {
			filter: brightness(150%);
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 30px;

		&__fogetPassword {
			color: $blue-1;
			font-size: 16px;
			font-weight: 700;
			cursor: pointer;
			margin-bottom: 20px;
		}

		&__signup {
			& > span:first-child {
				margin-right: 10px;
			}
			& > span:nth-child(2) {
				color: $blue-1;
				cursor: pointer;
			}
		}
	}
}

.title {
	font-weight: 600;
	font-size: 24px;
	line-height: 38px;
	color: $text-5;
	text-align: center;
}

.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.resentButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 20px;
	border: 2px solid $text-3;
	border-radius: 4px;
	color: $text-3;
	font-weight: 700;
	font-size: 18px;
	line-height: 16px;
	width: 200px;
	cursor: pointer;
}

.caption {
	font-weight: 400;
	font-size: 18px;
	line-height: 38px;
	color: $text-5;
	text-align: center;
}

.resentButtonDisabled{
	border: 2px solid $gray-10;
	color: $gray-8;
	cursor: not-allowed;
}


@media (max-width: 768px) {
	.form{
		width: 90%;
	}
}
