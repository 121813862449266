@import '../../../../common/colors';

.main {
	display: flex;
	flex-direction: column;
	align-items: center;

	&>label {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		color: $gray-8;
		margin-right: 10px;
	}
}

.inputContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	width: 300px;
	margin-top: 30px;
	margin-bottom: 20px;

	&>input {
		background: $white;
		border: 1px solid $border-4;
		box-shadow: 0px 2px 6px rgba(0, 66, 89, 0.06);
		border-radius: 6px;
		width: 60px;
		height: 80px;
		font-weight: 400;
		font-size: 32px;
		line-height: 74px;
		text-align: center;
		color: $text-5;
	}

	&__error {
		width: 100%;
		margin-top: 15px;
		text-align: center;
		font-weight: 700;
		font-size: 16px;
		line-height: 18px;
		color: $text-6;
	}
}

@media (max-width: 768px) {
	.inputContainer {
		width: 200px;
		margin-top: 20px;
		margin-bottom: 20px;

		&>input {
			width: 40px;
			height: 50px;
			font-size: 16px;
			line-height: 37px;

		}

		&__error {
			margin-top: 10px;
			font-size: 15px;
		}
	}
}
	
