.container {
	flex: 1 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.text {
	font-size: 30px;
}
