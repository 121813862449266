@import '../../common/colors';
@import '../../common/mixins';

.card {
	width: 380px;
	padding: 40px;
	background-color: $white;
	background: #FFFFFF;
	border: 1px solid $gray-2;
	box-shadow: 0px 2px 6px rgba(0, 66, 89, 0.06);
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&__title {
		font-weight: bolder;
		font-size: 24px;
		line-height: 28px;
		color: $gray-11;
	}

	&__subtitle {
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
		color: $gray-9;
		margin-top: 5px;
	}

	&__button {
		width: 100%;
		height: 50px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		cursor: pointer;

		&__label {
			margin-left: 10px;
			font-size: 18px;
		}
	}

	&__linkedin {
		background-color: $linkedin;
		margin-top: 40px;
		color: $white;
	}

	&__google {
		margin-top: 15px;
		border: 1px solid $border-2;
		box-shadow: 0px 4px 6px rgba(23, 23, 23, 0.06);
		color: $gray-11;

	}

	&__guest {
		margin-top: 25px;
		border: 1px solid $border-2;
		box-shadow: 0px 4px 6px rgba(23, 23, 23, 0.06);
		color: $gray-11;
		background-color: $bg-guest;
		color: $text-3;
	}

	&__footer {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		max-width: 300px;
		color: $gray-9;
		margin-top: 40px;
		text-align: center;

		&>span {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.inputContainerPassword {
	width: 100%;
	margin-bottom: 10px;
}

.inputContainer {
	width: 100%;
	margin-top: 40px;
	margin-bottom: 10px;
}

.input {
	max-width: none !important;
}