@import '../../common/colors';

.root {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: $border-1;
    margin-top: 20px;
    margin-bottom: 20px;
}

.menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    border-radius: 4px;
    color: $gray-4;
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% + 20px);
    margin-left: -10px;
    text-decoration: none;

    &__active {
        background-color: $dark-blue;
        color: $white;
    }

    &>span {
        margin-left: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
    }

    &>img {
        width: 18px;
        height: 18px;
    }

}