@import '../common/mixins';

#topNav {
    background-color: #1a3f5d;
    display: flex;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 65px;
    padding: 10px;
    align-items: center;
    z-index: 2;

    #menu {
        color: white;
        width: 24px;
        height: 24px;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    #title {
        text-align: center;
        width: 100%;
        font-size: 24px;
        font-weight: 700;

        a {
            text-decoration: none;
            color: white;
        }
    }
}

#leftBar {
    display: none;

    &.show {
        display: inherit;
    }

    background-color: #1a3f5d;
    left: 0;
    width: 250px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    position: fixed;
    top: 0;
    z-index: 2;

    &>section {
        padding: 25px;
    }
}

#help {
    color: white;
    font-size: 14px;
    padding: 24px;

    img {
        padding-right: 12px;
    }
}

#helpText {
    color: white;
    font-weight: 700;
}

#helpTime {
    color: #b2b9c9;
}

#logo {
    padding: 24px;
    font-size: 24px;
    font-weight: 700;

    // .show & {
    // 	display: none;
    // }

    a {
        text-decoration: none;
        color: white;
        font-weight: 700;
    }
}

.icon {
    color: #8791a7;
}

#overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1a3f5db8;
    z-index: 1;
}

#closeMenu {
    position: absolute;
    z-index: 3;
    left: 250px;
    color: white;
    width: 30px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1a3f5d;

    svg {
        width: 25px;
        height: 25px;
    }
}

main {
    padding-top: 65px;
}

@media (min-width: 900px) {
    #topNav {
        display: none;
    }

    #leftBar {
        display: flex;
        position: sticky;
        width: 230px;
    }

    main {
        padding-top: 0px;
    }
}

@media (min-width: 900px) {
    #leftBar {
        width: 250px;
    }
}

@media (min-width: 1200px) {
    #leftBar {
        width: 300px;
    }
}