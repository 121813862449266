$accent: #11c1cb;
$accent-text: #fff;

$primary: #1f59df;

$error: #d74747;

$red-1: #ff6262;

$blue-1: #1f59df;
$blue-2: #f0f5ff;
$dark-blue: #0c41ba;


$red-1: #FF6262;

$gray: #5d6984;
$light-gray: #dbe0e4;
$gray-1: #f5f6f9;
$gray-2: #dee2e9;
$gray-3: #c8cdd9;
$gray-4: #b2b9c9;
$gray-5: #9ca5b8;
$gray-6: #8791a7;
$gray-7: #727d96;
$gray-8: #5d6984;
$gray-9: #495672;
$gray-10: #35425f;
$gray-11: #222f4c;
$gray-12: #141e36;
$gray-13: #090f1d;

$bg-gray: #F5F6F9;

$white: #fff;

$green-1: #2e9f77;
$green-2: #4fc89d;
$green-3: #d2f0e4;
$green-4: #e8faf3;
$green-5: #4b655b;
$green-6: #E8FAF3;
$green-7: #2E9F77;


$aqua-1: #11c1cb;

$border-1: #285579;
$border-2: #f0f0f0;
$border-3: #DBE0E4;
$border-4: #DEE2E9;

$bg-guest: #F0F5FF;
$bg-account: #f5f6f9;
$bg-other-btn: #54617C;
$text-1: #828895;
$text-2: #222F4C;
$text-3: #1F59DF;
$text-4: #80838C;
$text-5: #35425F;
$text-6: #E35151;
$text-th: #333537;
$placeholder: #9599A4;
$linkedin: #006699;